<template>
  <div class="mapRenderTool">
    <van-field v-model="roomName" label="房间名称" placeholder="请输入房间名称"/>
    <van-field v-model="roomHeight" type="number" label="房间高度" placeholder="请输入房间高度"/>
    <van-cell title="房间颜色">
      <template #extra>
        <el-color-picker v-model="roomColor" size="mini" @change="changeColor"></el-color-picker>
      </template>
    </van-cell>
    <div class="footer">
      <van-button type="default" @click="cancel">取消</van-button>
      <van-button type="default" @click="resetRoom">重置</van-button>
      <van-button type="info" @click="sure">确定</van-button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'mapRenderTool',
  props: {
    roomInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    roomInfo: {
      handler(value) {
        this.reset()
      }
    }
  },
  data() {
    return {
      roomName: '',
      roomHeight: 0,
      roomColor: '#99f3bd'
    }
  },
  methods: {
    reset: function() {
      this.roomName = this.$getDataValue(this.roomInfo, 'title', '')
      this.roomHeight = this.$getDataValue(this.roomInfo, 'height', 0)
      this.roomColor = this.$getDataValue(this.roomInfo, 'color', '#99f3bd')
    },
    changeColor: function() {
      this.$emit('changeColor', { color: this.roomColor })
    },
    resetRoom: function() {
      this.reset()
      this.$emit('resetRoom', {})
    },
    cancel: function() {
      this.reset()
      this.$emit('cancel', {})
    },
    sure: function() {
      let roomInfo = {
        titleLV:3,
        areaid: this.$getDataValue(this.roomInfo, 'areaid', ''),
        title: this.roomName,
        inZone: this.$getDataValue(this.roomInfo, 'inZone', ''),
        zoneTitle: this.$getDataValue(this.roomInfo, 'zoneTitle', ''),
      }
      let position = {
        x: this.$getDataValue(this.roomInfo, 'x', 0),
        z: this.$getDataValue(this.roomInfo, 'z', 0),
        y: parseFloat(this.roomHeight),
      }
      this.$emit('sure', { position: position, roomHeight: this.roomHeight, roomInfo: roomInfo })
    }
  }
}
</script>

<style lang="scss" scoped>

.mapRenderTool {
  position: absolute;
  bottom: 10px;
  width: 90%;
  transform: translate(5.555%, 0px);
  background-color: white;
  border-radius: 9px;
  overflow: hidden;
  box-shadow: 4px 4px 24px 0px rgba(160, 170, 199, 0.4);

  .footer {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;

  }

}

</style>