<template>
  <div class="mapRenderView" id="mapRenderView">
    <map-render-tool v-show="visible" :room-info="roomInfo" @cancel="cancel" @resetRoom="resetRoom"
                     @changeColor="changeColor" @sure="sure"
    ></map-render-tool>
  </div>
</template>

<script>
import { Loading } from 'element-ui'
import { createModule } from '@/assets/JS/moduleJs'
import MapRenderTool from '@views/mapRender/mapRenderView/mapRenderTool'

export default {
  name: 'mapRenderView',
  components: { MapRenderTool },
  watch: {
    loading: {
      handler(value) {
        if (value) {
          this.startLoading()
        } else {
          if (this.loadingView == null) {
            return
          }
          this.loadingView.close()
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      stroeInfo: {},
      visible: false,
      touch: null,
      module: createModule(),
      loading: false,
      loadingView: null,
      roomInfo: {}
    }
  },
  beforeDestroy() {
    this.module.renderRemoveEvent([
      { key: 'touchstart', func: this.touchStart },
      { key: 'touchend', func: this.touchEnd },
      { key: 'touchmove', func: this.touchMove }
    ])
    this.module.realseMap()
  },
  mounted() {
    this.initMap('mapRenderView', () => {
      this.getMapDetail()
    })
  },
  methods: {
    resetRoom: function() {
      this.module.getMap().changeRoomColor({ idInfo: {}, reset: true })
    },
    sure: function(res) {
      let query = this.$route.query
      if (typeof query['mapId'] == 'undefined') {
        resolve({ result: false })
        return
      }
      let mapId = query['mapId']
      this.module.getMap().getText().updateMapTitle(res.roomInfo, res.position, {mode:0, mapid: mapId })
    },
    changeColor: function(res) {
      if (typeof this.roomInfo['areaid'] == 'undefined') {
        return
      }
      let idInfo = {}
      idInfo[this.roomInfo['areaid']] = '1'
      this.module.getMap().changeRoomColor({ idInfo: idInfo, color: res.color, reset: false })
    },
    cancel: function() {
      this.visible = false
      this.roomInfo = {}
      this.module.getMap().getPoint().updatePointsInfo({ clickPoint: [] }, {})
    },
    touchStart: function(event) {
      this.touch = event
    },
    touchMove: function(event) {
      // 给予10px的容错范围
      if (this.touch != null) {
        let error = 10
        let startTouch = this.touch.touches[0]
        let nextouch = event.touches[0]
        if (typeof (startTouch.clientX) != 'undefined' && typeof (startTouch.clientY) != 'undefined' && typeof (nextouch.clientX) != 'undefined' && typeof (nextouch.clientY) != 'undefined') {
          let dis = Math.sqrt((startTouch.clientX - nextouch.clientX) * (startTouch.clientX - nextouch.clientX) + (startTouch.clientY - nextouch.clientY) * (startTouch.clientY - nextouch.clientY))
          if (dis > error) {
            this.touch = null
          }
        } else {
          this.touch = null
        }
      } else {
        this.touch = null
      }
    },
    touchEnd: function(event) {
      if (this.touch == null) {
        return
      }
      const res = this.module.clickMapRoomEvent(this.touch, null, { moveCamera: true, titleLimit: false })
      let pointList = []
      if (res.result) {
        let data = res.data
        pointList.push({
          mapid: data.mapid,
          x: data.x,
          y: 3,
          z: data.z,
          type: 'clickPoint',
          centerY: 0.1414
        })
        this.roomInfo = res.data
        console.log(res.data)
        let areaid = this.roomInfo['areaid']
        if (typeof this.stroeInfo[areaid] == 'undefined') {
          this.stroeInfo[areaid] = {
            areaid: areaid,
            title: this.roomInfo['title'],
            height: this.roomInfo['height'],
            color: this.roomInfo['color']
          }
        }
      } else {
        this.roomInfo = {}
      }
      this.visible = pointList.length > 0
      this.module.getMap().getPoint().updatePointsInfo({ clickPoint: pointList }, {})
      this.touch = null
    },
    startLoading: function() {
      let dom = document.getElementById('mapRenderView')
      if (typeof dom == 'undefined' || dom == null) {
        return
      }
      this.loadingView = Loading.service({ target: dom })
    },
    initMap: function(divId, callBack) {
      this.$nextTick(() => {
        const div = document.getElementById(divId)
        if (div == null) {
          callBack({ result: false })
          return
        }
        this.module.createMap(divId, div.offsetWidth, div.offsetHeight, 0) // 先初始化地图参数
        this.module.setMapConfig([{ type: 'controller', config: { zoomSize: true } }])
        this.module.renderAddNewEvent([
          { key: 'touchstart', func: this.touchStart },
          { key: 'touchend', func: this.touchEnd },
          { key: 'touchmove', func: this.touchMove }
        ])
        setTimeout(() => {
          callBack({ result: true })
        }, 500)
      })
    },
    getMapDetail: function() {
      let getData = () => {
        return new Promise(resolve => {
          let query = this.$route.query
          if (typeof query['mapId'] == 'undefined') {
            resolve({ result: false })
            return
          }
          let mapId = query['mapId']
          this.loading = true
          this.$api.mapApi.getMapDetail({
            id: mapId
          }).then(res => {
            const json = res.data.json
            const zoneInfoStr = res.data.zoneInfo
            try {
              const mapInfo = JSON.parse(json)
              const zoneInfo = JSON.parse(zoneInfoStr)
              mapInfo['zoneInfo'] = zoneInfo
              mapInfo['extraInfo'] = []
              resolve({ result: true, mapList: [mapInfo], mapId: mapId })
            } catch (res) {
              resolve({ result: true, mapList: [], mapId: '' })
            }
          }).catch(e => {
            resolve({ result: false })
          })
        })
      }

      this.loading = true
      getData().then(res => {
        this.loading = false
        if (res.result == false) {
          this.module.deleteMapGroup()
          return
        }
        this.module.updateMapDataInfo(res.mapList, { lazyLoad: false }, list => {
          this.module.showMap(res.mapId)
        })

      })
    }
  }
}
</script>

<style lang="scss" scoped>

.mapRenderView {
  width: 100%;
  height: 100%;
}

</style>